import { Button } from "@chakra-ui/react";
import React from "react";
import { WholesaleEntity } from "../constants/Wholesale";

export default function test() {
  const onClick = () => {};

  return (
    <Button bg="black" color="white" onClick={onClick}>
      Press Me
    </Button>
  );
}

const dummyCustomer: WholesaleEntity = {
  companyName: "Walmart",
  companyEmail: "wal@mart.com",
  companyPhone: "305-678-1212",
  billingAddress: "10801 SW 115th st",
  billingCity: "Miami",
  billingState: "Florida",
  billingZip: "33186",
  shippingAddress: "10901 NW 114th ave",
  shippingCity: "Aventura",
  shippingState: "Florida",
  shippingZip: "02120",
  taxIdNumber: "123456",
};
